import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Image, Text } from "../Core"
import "./_widgets.scss"
import Button from "../Buttons/Button"
import MobileCarousel from "../Carousels/MobileCarousel"

const InsightsWidget = ({ heading = "Stay Informed, Stay Inspired!" }) => {
  const { insights } = useStaticQuery(graphql`
    query insightsQuery {
      insights: allInsightsJson(
        limit: 3
        filter: { query: { image: { ne: null } }, hasThisPage: { eq: true } }
        sort: { order: DESC, fields: createdAt }
      ) {
        nodes {
          title
          heading
          query {
            blurb
            image
            mobileImage
          }
          heroImage
        }
      }
    }
  `)

  return (
    <div className="widget-insight">
      <Text className="widget-insight__heading" as="h5" text={heading} />

      <div className="widget-insight__list is-hidden-touch">
        {insights.nodes.map((insight, idx) => (
          <Link
            to={insight.title}
            title="Read the full article"
            key={insight.title}
            className={`widget-insight__card card-${idx + 1}`}>
            <div className="card-image rounded">
              <Image publicId={insight.query.image} />
            </div>
            <div className="card-content">
              <Text as="h5" text={insight.heading} />
              <p>{insight.query.blurb}</p>
              {idx === 0 && (
                <div className="card-btn standard-button contained">
                  Look Into Our Insights
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>

      <div className="widget-insight__carousel is-hidden-desktop">
        <MobileCarousel arrows={false}>
          {insights.nodes.map((insight, idx) => (
            <div key={insight.title}>
              <div className={`widget-insight__card card-${idx + 1}`}>
                <div className="card-image rounded">
                  <Image publicId={insight.query.mobileImage} />
                </div>
                <div className="card-content">
                  <h5>{insight.heading}</h5>
                  <p>{insight.query.blurb}</p>
                </div>
              </div>
            </div>
          ))}
        </MobileCarousel>
        <div className="card-btn">
          <Button
            appearance="contained"
            buttonText="Look Into Our Insights"
            href={"/insights/"}
            destination="internal"
          />
        </div>
      </div>
    </div>
  )
}

export default InsightsWidget
