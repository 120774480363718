import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Section } from "../components/Layouts"
import SEO from "../components/seo"
import Video from "../components/Video/Video"
import Sizzle from "../videos/sizzle-home.mp4"
import SizzleMobile from "../videos/sizzle-home-mobile.mp4"
import { Image, Text } from "../components/Core"
import Button from "../components/Buttons/Button"
import MobileCarousel from "../components/Carousels/MobileCarousel"
import InsightsWidget from "../components/Widgets/Insights"
import UpRightArrow from "../../static/images/up-right-arrow.svg"

const Homepage = ({ data, pageContext, location, path }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { language } = pageContext
  const { about, wins, services, work, ai } = post

  const winsList = () =>
    wins.areasList.map((area, index) => (
      <div key={index} className="homepage__wins-grid-item rounded">
        <Text
          as="h3"
          text={area.heading}
          className="homepage__wins-grid-item-heading"
        />
        <Text
          role="heading"
          aria-level="3"
          text={area.number}
          className="homepage__wins-grid-item-number"
        />
        <Text
          role="heading"
          aria-level="3"
          text={area.blurb}
          className="homepage__wins-grid-item-blurb"
        />
      </div>
    ))

  return (
    <Layout
      className={"homepage"}
      language={language}
      theme="clear"
      path={path}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={post.schema ?? null}
        pathname={location.pathname}
      />

      <div className="is-hidden-touch">
        <Video src={Sizzle} />
      </div>
      <div className="is-hidden-desktop mobile-sizzle">
        <Video aspectRatio="177.25%" src={SizzleMobile} />
      </div>

      <Section zeroTop zeroBottom className="homepage__hero-container">
        <Container Xlarge>
          <div className="homepage__hero">
            <Text as="h1" text={post.heading} />

            <div className="homepage__about">
              <div className="homepage__about-header">
                <UpRightArrow className="is-hidden-touch" />
                <Text as="h2" className="color-blue" text={about.heading} />
              </div>
              <div>
                <Text text={about.blurb} />
                <Button {...about.button} minimal />
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="homepage__wins-section rounded">
        <Container>
          <div className="homepage__wins">
            <Text
              as="p"
              role="presentation"
              text={wins.title}
              className="homepage__wins-title"
            />
            <Text as="h3" className="mt-1 mb-1" text={wins.heading} />
            <Text as="p" text={wins.blurb} className="homepage__wins-blurb" />
            <div className="homepage__wins-grid is-hidden-touch">
              {winsList()}
              <div className="homepage__wins-image">
                <Image publicId={"NUVO/V4/success-metrics-nuvo-success"} />
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="is-hidden-desktop">
        <div className="homepage__wins-slider">
          <MobileCarousel>{winsList()}</MobileCarousel>
          <div className="homepage__wins-mobile-image">
            <Image publicId={"NUVO/V4/success-metrics-nuvo-success"} />
          </div>
        </div>
      </Section>

      <Section>
        <Container>
          <div className="homepage__services">
            <div className="homepage__services-heading relative rounded">
              <Image
                useAR={false}
                width="813"
                height="522"
                className="desktop homepage__services-image"
                publicId={services.desktopImage}
              />
              <Image
                useAR={false}
                width="390"
                height="348"
                className="mobile homepage__services-image"
                publicId={services.mobileImage}
              />
              <Text className="color-white" as="h3" text={services.heading} />
            </div>
            <div className="homepage__services-blurb">
              <Text text={services.blurb} />
              <Button {...services.button} />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="homepage__work">
            <div className="homepage__work-text">
              <Text
                as="h4"
                className="homepage__work-subheading color-blue"
                text={work.subheading}
              />
              {/* <Text as="h3" text={work.heading} /> */}
              <div className="homepage__work-offset">
                <Text text={work.blurb} />
                <Button {...work.button} minimal />
              </div>
            </div>
            <div className="homepage__work-image">
              <Image publicId={work.image} width="595" height="505" />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <div className="homepage__ai relative">
          <Image
            className="is-hidden-touch"
            useAR={false}
            publicId={ai.image}
          />
          <Image
            className="is-hidden-desktop"
            useAR={false}
            publicId={ai.mobileImage}
          />
          <div className="homepage__ai-content">
            <Text
              className="ai-heading mb-1 color-white"
              as="h4"
              text={ai.heading}
            />
            <Text className="ai-blurb color-white" as="p" text={ai.blurb} />
            <Button {...ai.button} />
          </div>
        </div>
      </Section>

      <Section>
        <Container>
          <InsightsWidget />
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        schema
        metaTitle
        metaDescription
        heading
        about {
          heading
          blurb
          button {
            buttonText
            destination
            appearance
            href
          }
        }
        wins {
          title
          heading
          blurb
          areasList {
            heading
            blurb
            number
          }
        }
        services {
          heading
          blurb
          desktopImage
          mobileImage
          button {
            buttonText
            destination
            appearance
            href
          }
        }
        work {
          heading
          subheading
          blurb
          image
          button {
            buttonText
            destination
            appearance
            href
          }
        }
        ai {
          heading
          blurb
          image
          mobileImage
          button {
            buttonText
            href
            destination
            appearance
          }
        }
        insights {
          heading
        }
      }
    }
  }
`

export default Homepage
